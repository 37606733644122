// from yarn
import "jquery";
import "bulma";
import slick from "slick-carousel";

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("turbolinks").start();

// javascripts
import "src/pages/pages/contents/service.js";

// stylesheets
import "stylesheets/pages/contents/service.scss";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

// images
const images = require.context("../../../images", true);
const imagePath = (name) => images(name, true);
