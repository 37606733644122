$(function () {
  $(".js-carousel").slick({
    dots: true,
    infinite: false,
    prevArrow: '<span class="service-mv__slick-prev"></span>',
    nextArrow: '<span class="service-mv__slick-next"></span>',
  });

  $(".js-modal-open").each(function () {
    $(this).on("click", function () {
      let target = $(this).data("target");
      let modal = document.getElementById(target);
      $(modal).fadeIn(300);
      bodyScrollPrevent(true);
    });
    $(".js-modal-close, .service-mv-modal__background").on(
      "click",
      function () {
        $(".js-modal").fadeOut(300, function () {
          bodyScrollPrevent(false);
        });
      }
    );
  });

  function bodyScrollPrevent(flag) {
    let scrollPosition;
    const body = document.getElementsByTagName("body")[0];
    const ua = window.navigator.userAgent.toLowerCase();
    const isiOS =
      ua.indexOf("iphone") > -1 ||
      ua.indexOf("ipad") > -1 ||
      (ua.indexOf("macintosh") > -1 && "ontouchend" in document);

    const scrollBarWidth = window.innerWidth - document.body.clientWidth;
    if (flag) {
      body.style.paddingRight = scrollBarWidth + "px";
      if (isiOS) {
        scrollPosition = -window.pageYOffset;
        body.style.position = "fixed";
        body.style.width = "100%";
        body.style.top = scrollPosition + "px";
      } else {
        body.style.overflow = "hidden";
      }
    } else if (!flag) {
      body.style.paddingRight = "";
      if (isiOS) {
        scrollPosition = parseInt(body.style.top.replace(/[^0-9]/g, ""));
        body.style.position = "";
        body.style.width = "";
        window.scrollTo(0, scrollPosition);
      } else {
        body.style.overflow = "";
      }
    }
  }
});

(function () {
  // 元のmethodを保存
  const originalAddClassMethod = jQuery.fn.addClass;

  jQuery.fn.addClass = function () {
    // 元のmethodを実行
    let result = originalAddClassMethod.apply(this, arguments);

    // カスタムイベントを発火
    jQuery(this).trigger("cssClassAdd");

    return result;
  };

  $(".js-carousel").bind("cssClassAdd", function () {
    var cateFlg = $(".slick-active").find(".js-carousel-item01").length;
    var itemFlg = $(".slick-active").find(".js-carousel-item02").length;
    let $adviser01 = $(".service-mv__adviser-wrap01");
    let $adviser02 = $(".service-mv__adviser-wrap02");

    if (cateFlg) {
      $adviser01.css("display", "block");
      $adviser02.css("display", "none");
    }

    if (itemFlg) {
      $adviser01.css("display", "none");
      $adviser02.css("display", "block");
    }
  });
})();
